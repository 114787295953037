import React, { Suspense } from "react";
import { hydrate, render } from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";

import App from "./app/App";
import { ToastsProvider } from "./app/providers/ToastsProvider";
import { GlobalStyle } from "./app/styles";
import { ErrorFallback } from "./components/presenters";
import "configs/i18n/i18n.js";

const rootElement = document.querySelector("#root");

function Root() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <GlobalStyle />
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload(true)}>
          <App />
          <ToastsProvider />
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  );
}

if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}
