import basicConfig from "./basicConfig";
const _ = undefined;

const singleSearchAPI = {
  initiateSingleSearch(payload) {
    return basicConfig
      .createRequest("/v1/footprints-by-parcel", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },
  getAllAddresses(payload) {
    return basicConfig
      .createRequest("/v1/all-parcels", "GET", { "content-type": "application/json" }, _, _, payload)
      .then((res) => res.data);
  },
  getSingleSearchHistory() {
    return basicConfig.createRequest("/v1/single-search/history?page_num=1&page_size=1", "GET").then((res) => res.data);
  },

  getPointsForPolygons(payload) {
    return basicConfig
      .createRequest("/v1/get-parcels-within-corners", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data.data.parcels);
  },
};

export default singleSearchAPI;

// 50 HOPETOUN AV, KILBURN SA 5084
