import React from "react";

import { ReactComponent as PreviousIcon } from "assets/icons/sort-down.svg";

import * as Styled from "./style";

export function PreviousButton({ onClick, style = {}, ...rest }) {
  return (
    <Styled.ButtonWrapper onClick={onClick} style={style} {...rest}>
      <PreviousIcon style={{ transform: "rotate(90deg)", width: "12px" }} />
    </Styled.ButtonWrapper>
  );
}
