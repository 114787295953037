import styled, { css } from "styled-components";

import { baseButtonStyle, BORDER_RADIUS, COLORS, WEIGHT, BOX_SHADOW } from "configs/styles/common";

import { text } from "../Typography";
import { BUTTON_VARIANT } from "./button-variants";

export const Button = styled.button`
  ${baseButtonStyle};
  ${text};
  font-weight: ${WEIGHT.medium};
  text-transform: capitalize;
  box-shadow: ${BOX_SHADOW.LIGHT};
  border-radius: ${BORDER_RADIUS.XS};
  width: 100%;
  padding: 5px 10px;
  height: 45px;

  &:focus {
    outline: 1.5px solid ${COLORS.DARK.Main};
  }

  &:active {
    translate: 1px 1px;
  }

  ${({ variant }) =>
    variant === BUTTON_VARIANT.ACCENT &&
    css`
      background-color: ${COLORS.ACCENT};
      color: ${COLORS.DARK.Main};
      border: 1px solid ${COLORS.ACCENT};
      transition: background-color, translate, box-shadow, border-color;
      transition-duration: 220ms;
      transition-timing-function: ease-in;

      &:hover {
        background-color: #ffd502;
        box-shadow: 0 0 11px rgba(33 33 33/ 15%);
        border-color: #ffd502;
      }

      &:disabled {
        opacity: 0.7;
        cursor: default;

        &:hover {
          background-color: ${COLORS.ACCENT};
        }
      }
    `}

  ${({ variant }) =>
    variant === BUTTON_VARIANT.CONTAINED &&
    css`
      background-color: ${COLORS.DARK.Brown};
      color: ${COLORS.LIGHT.White};
      position: relative;
      border: 1px solid ${COLORS.DARK.Brown};
      z-index: 1;
      font-weight: ${WEIGHT.regular};
      transition: opacity 220ms ease-in, translate 200ms;

      &:hover {
        opacity: 0.9;
      }

      &:disabled {
        opacity: 0.7;
        cursor: default;
      }
    `}


  ${({ variant }) =>
    variant === BUTTON_VARIANT.OUTLINE &&
    css`
      background-color: ${COLORS.LIGHT.White};
      box-shadow: ${BOX_SHADOW.LIGHT};
      border: 2px solid ${COLORS.LIGHT.Slate};
      color: ${COLORS.DARK.Main};
      transition: background-color 220ms ease-in, translate 200ms;

      &:hover {
        background-color: ${COLORS.LIGHT.Slate};
      }
    `}


  ${({ variant }) =>
    variant === BUTTON_VARIANT.GHOST &&
    css`
      background-color: transparent;
      color: ${COLORS.DARK.Main};
      box-shadow: none;
      font-weight: 400;

      &:focus {
        outline: none;
      }
    `}
`;
