import React from "react";
import { useTranslation } from "react-i18next";

import { useMap, MAP_TYPE } from "app/providers/business/MapProvider";
import { SelectControl, MapTypeLabel } from "components/presenters/CustomGoogleMap/style";

export function MapTypeControl({ map }) {
  const { t, i18n } = useTranslation();
  const { activeMapType, setActiveMapType } = useMap();

  function onMapTypeChange(event) {
    setActiveMapType(event.target.value);
    map.setMapTypeId(event.target.value);
  }

  return (
    <form>
      <SelectControl
        type="radio"
        name="show-hide"
        id="show-white"
        value={MAP_TYPE.WHITE}
        checked={activeMapType === MAP_TYPE.WHITE}
        onChange={onMapTypeChange}
      />
      <MapTypeLabel htmlFor="show-white" lang={i18n.language}>
        {t("White")}
      </MapTypeLabel>

      <SelectControl
        type="radio"
        name="show-hide"
        id="show-road"
        value={MAP_TYPE.ROAD}
        checked={activeMapType === MAP_TYPE.ROAD}
        onChange={onMapTypeChange}
      />
      <MapTypeLabel htmlFor="show-road" lang={i18n.language}>
        {t("Road")}
      </MapTypeLabel>

      <SelectControl
        type="radio"
        name="show-hide"
        id="show-satellite"
        value={MAP_TYPE.SATELLITE}
        checked={activeMapType === MAP_TYPE.SATELLITE}
        onChange={onMapTypeChange}
      />
      <MapTypeLabel htmlFor="show-satellite" lang={i18n.language}>
        {t("Satellite")}
      </MapTypeLabel>
    </form>
  );
}
