import Cookies from "js-cookie";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { S3 } from "services/constants";
import { ROUTE_PATHS } from "app/providers/router";

import * as Styled from "./styles";

export const LinkedinPNG = S3 + "assets/linkedin.png";
export const YoutubePNG = S3 + "assets/youtube.png";
const policy = S3 + "assets/GeoX_Website_Privacy_Policy.docx";

export function Footer() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Styled.Footer>
      <Styled.Container>
        <Styled.LogoWrapper>
          <Styled.Logo
            onClick={() => {
              history.push(ROUTE_PATHS.HOME);
              window.scrollTo(0, 0);
            }}
          />
        </Styled.LogoWrapper>

        <Styled.NavList>
          <Styled.ListItem>
            <Styled.ListName>{t("Navigation")}</Styled.ListName>
          </Styled.ListItem>
          <Styled.ListItem>
            <Styled.Link onClick={() => window.scrollTo(0, 0)} to="/home">
              {t("Home")}
            </Styled.Link>
          </Styled.ListItem>

          {Cookies.get("isLogin") !== "true" && (
            <Styled.ListItem>
              <Styled.Link onClick={() => window.scrollTo(0, 0)} to="/sign-in">
                {t("Login")}
              </Styled.Link>
            </Styled.ListItem>
          )}
        </Styled.NavList>

        <Styled.NavList>
          <Styled.ListItem>
            <Styled.ListName>{t("Help")}</Styled.ListName>
          </Styled.ListItem>
          <Styled.ListItem>
            <Styled.Email href="mailto:info@geox-group.com">{t("info@geox-group.com")}</Styled.Email>
          </Styled.ListItem>
          <Styled.ListItem>
            <Styled.FileLink href={policy}>{t("Privacy Policy")}</Styled.FileLink>
          </Styled.ListItem>
        </Styled.NavList>

        <Styled.NavList>
          <Styled.ListName>{t("Visit us")}</Styled.ListName>
          <Styled.SocialRow>
            <Styled.SocialLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.youtube.com/channel/UCIko_wVDSwU8RWKhJbIHzFg/featured"
            >
              <Styled.SocialIcon src={YoutubePNG} width="52px" height="36px" />
            </Styled.SocialLink>
            <Styled.SocialLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/geox-gis-innovations/mycompany"
            >
              <Styled.SocialIcon src={LinkedinPNG} width="36px" height="36px" />
            </Styled.SocialLink>
          </Styled.SocialRow>
        </Styled.NavList>
        <Styled.EmptyBlock />
      </Styled.Container>
    </Styled.Footer>
  );
}
