import Cookies from "js-cookie";
import React from "react";
import { Redirect, withRouter } from "react-router-dom";

import { ROUTE_PATHS } from "app/providers/router";

import BaseRoute from "./BaseRoute";

function PrivateRoute({ ...rest }) {
  return Cookies.get("isLogin") === "true" ? (
    <BaseRoute {...rest} />
  ) : (
    <Redirect to={{ pathname: ROUTE_PATHS.SIGN_IN, state: { from: rest.location } }} />
  );
}

export default withRouter(PrivateRoute);
