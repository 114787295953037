import React, { createContext, useContext, useMemo } from "react";

import { useStorage } from "services/hooks";

export const MAP_TYPE = {
  WHITE: "white",
  SATELLITE: "satellite",
  ROAD: "roadmap",
};

const MapContext = createContext();

export function MapProvider({ children }) {
  const [activeMapType, setActiveMapType] = useStorage("mapType", MAP_TYPE.WHITE);

  const value = useMemo(() => ({ activeMapType, setActiveMapType }), [activeMapType, setActiveMapType]);

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}

export function useMap() {
  return useContext(MapContext);
}
