import styled from "styled-components/macro";

import { iconButtonStyle, QUERIES } from "configs/styles/common";

export const ButtonWrapper = styled.button`
  ${iconButtonStyle};
  width: 42px;
  height: 42px;
  flex-shrink: 0;

  @media ${QUERIES.upToMobile} {
    height: 35px;
  }
`;
