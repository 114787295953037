import { COLORS } from "configs/styles/common";

export const MAP_CENTER = {
  au: { lat: -34.86561704, lng: 138.5889934 },
};

export const MAP_LOADER = {
  googleMapsApiKey: "AIzaSyCAnsgJfHMKos7pSCKdR64eS9hR5KeiS3g",
  libraries: ["places", "drawing"],
};

export const DEFAULT_MAP_CONTAINER_STYLE = {
  width: "100%",
  height: "100%",
};

export const MAP_CONTAINER_STYLE = {
  width: "100%",
  borderRadius: "10px",
  height: "100%",
};

export const POLYGON_OPTIONS = {
  fillColor: "transparent",
  strokeColor: COLORS.ACCENT,
  fillOpacity: 0.7,
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
