import React, { memo, forwardRef } from "react";

import * as Styled from "./Button.style";

const Button = forwardRef(function Button(
  { onClick, children, isDisabled = false, variant = "accent", type = "button", ...delegated },
  ref
) {
  return (
    <Styled.Button
      type={type}
      onClick={() => onClick?.()}
      variant={variant}
      disabled={isDisabled}
      ref={ref}
      {...delegated}
    >
      {children}
    </Styled.Button>
  );
});

export default memo(Button);
