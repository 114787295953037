import styled from "styled-components/macro";

import { BORDER_RADIUS, BOX_SHADOW, centerStyle, QUERIES, WEIGHT } from "configs/styles/common";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;

  @media ${QUERIES.upToMobile} {
    gap: 10px;
  }
`;

export const CurrentBuilding = styled.div`
  ${centerStyle};
  box-shadow: ${BOX_SHADOW.REGULAR};
  border-radius: ${BORDER_RADIUS.XS};
  height: 42px;
  width: 100%;

  @media ${QUERIES.upToMobile} {
    height: 35px;
  }
`;

export const Text = styled.p`
  font-weight: ${WEIGHT.semiBold};
  font-size: 16px;
  line-height: 19px;

  @media ${QUERIES.upToMobile} {
    font-weight: ${WEIGHT.medium};
    font-size: 14px;
    line-height: 16px;
  }
`;
