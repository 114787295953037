import React from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { QUERIES } from "configs/styles/common";
import { useBooleanState } from "services/hooks";
import { ROUTE_PATHS } from "app/providers/router";

import { MobileMenu, NavigationLink, Logo, MyAccount } from "./components";
import * as Styled from "./style";

export function Header() {
  const isTabletOrMobile = useMediaQuery({ query: QUERIES.upToTablet });
  const isDesktop = !isTabletOrMobile;
  const isAuthorized = Cookies.get("isLogin") === "true";

  const [isMenuOpen, setNotMenuOpen, setIsMenuOpen] = useBooleanState();

  const { t } = useTranslation();

  return (
    <>
      {isTabletOrMobile && (
        <>
          <Styled.HeaderSpace />
          <Styled.HeaderWrapper>
            <Styled.Header>
              <Logo />

              <Styled.HeaderBox>
                <Styled.Menu onClick={setIsMenuOpen} aria-label="menu" />
              </Styled.HeaderBox>
            </Styled.Header>
          </Styled.HeaderWrapper>

          <MobileMenu open={isMenuOpen} close={setNotMenuOpen} />
        </>
      )}

      {isDesktop && isAuthorized && (
        <>
          <Styled.HeaderWrapper>
            <Styled.Header>
              <Styled.MainNavigation>
                <Logo />
                <NavigationLink to={ROUTE_PATHS.SINGLE_SEARCH}>{t("Search")}</NavigationLink>
              </Styled.MainNavigation>

              <Styled.MainNavigation smallGap>
                <MyAccount />
              </Styled.MainNavigation>
            </Styled.Header>
          </Styled.HeaderWrapper>
        </>
      )}

      {isDesktop && !isAuthorized && (
        <>
          <Styled.HeaderWrapper>
            <Styled.Header>
              <Styled.MainNavigation>
                <Logo />
                <NavigationLink to={ROUTE_PATHS.HOME}>{t("Home")}</NavigationLink>
              </Styled.MainNavigation>
              <Styled.MainNavigation smallGap>
                <NavigationLink to={ROUTE_PATHS.SIGN_IN}>{t("Sign In")}</NavigationLink>
              </Styled.MainNavigation>
            </Styled.Header>
          </Styled.HeaderWrapper>
        </>
      )}
    </>
  );
}
