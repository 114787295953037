import React from "react";

import { ReactComponent as NextIcon } from "assets/icons/sort-down.svg";

import * as Styled from "./style";

export function NextButton({ onClick, style = {}, ...rest }) {
  return (
    <Styled.ButtonWrapper onClick={onClick} style={style} {...rest}>
      <NextIcon style={{ transform: "rotate(-90deg)", width: "13px" }} />
    </Styled.ButtonWrapper>
  );
}
