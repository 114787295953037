import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { PublicRoute, BaseRoute, PrivateRoute } from "services/helpers/routing";

import { MapProvider } from "../business";
import { ROUTE_PATHS } from "./consts";
import { CookieNotice, HomePage, NotFoundPage, Privacy, SignInPage, SingleSearchPage, Terms } from "../../../pages";

export default function AppRouter() {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <PublicRoute path="/" exact component={HomePage} />
        <PublicRoute path={ROUTE_PATHS.HOME} component={HomePage} />

        <BaseRoute path={ROUTE_PATHS.SIGN_IN} component={SignInPage} />

        <BaseRoute path={ROUTE_PATHS.COOKIE_NOTICE} component={CookieNotice} />
        <BaseRoute path={ROUTE_PATHS.PRIVACY} component={Privacy} />
        <BaseRoute path={ROUTE_PATHS.TERMS} component={Terms} />

        <MapProvider>
          <PrivateRoute exact path={ROUTE_PATHS.SINGLE_SEARCH} component={SingleSearchPage} />
          <PrivateRoute
            path={ROUTE_PATHS.SINGLE_SEARCH_RESULT({
              parcelId: ":parcelId",
              dataType: ":dataType",
              buildingNum: ":buildingNum",
            })}
            component={SingleSearchPage}
          />
        </MapProvider>

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
}
