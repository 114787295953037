import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { NextButton, PreviousButton } from "./NavigationButtons";
import * as Styled from "./style";

export function BuildingNavigator({ totalBuildings, currentBuilding, href }) {
  const { t } = useTranslation("attributes");
  const history = useHistory();
  const { addressId } = useParams();

  const isNextBuilding = parseInt(currentBuilding) < totalBuildings;
  const isPreviousBuilding = parseInt(currentBuilding) > 1;

  function onNextButtonClick() {
    const nextBuilding = parseInt(currentBuilding) + 1;
    history.push(href({ addressId, buildingNum: nextBuilding }));
  }

  function onPreviousButtonClick() {
    const previousBuilding = parseInt(currentBuilding) - 1;
    history.push(href({ addressId, buildingNum: previousBuilding }));
  }

  return (
    <Styled.Wrapper>
      <Styled.CurrentBuilding>
        <Styled.Text>
          {t("Building")} {currentBuilding}
        </Styled.Text>
      </Styled.CurrentBuilding>

      {isPreviousBuilding && <PreviousButton onClick={onPreviousButtonClick} />}

      {isNextBuilding && <NextButton onClick={onNextButtonClick} />}
    </Styled.Wrapper>
  );
}
