import React, { useEffect } from "react";
import ReactGA from "react-ga";

import { ScrollToTop } from "services/helpers/common";

import { CookieBanner } from "../components/presenters";
import { SEO } from "../configs/seo";
import AppRouter from "./providers/router/AppRouter";

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_GA_ENABLED === "true") {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <div className="App">
      <SEO title={"GeoX Property Intelligence"} titleTemplate="" />
      <CookieBanner />
      <ScrollToTop>
        <AppRouter />
      </ScrollToTop>
    </div>
  );
}

export default App;
