export const ROUTE_PATHS = {
  HOME: "/home",
  SIGN_IN: "/sign-in",
  COOKIE_NOTICE: "/cookie-notice",
  PRIVACY: "/privacy",
  TERMS: "/terms",
  SINGLE_SEARCH: "/single-search",
  SINGLE_SEARCH_RESULT: ({ parcelId, buildingNum, dataType = "recent" }) =>
    `/single-search/${parcelId}/${dataType}/${buildingNum}`,
};
