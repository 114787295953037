import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { COLORS } from "configs/styles/common";

export function NavigationLink({ to, children, ...delegated }) {
  const { i18n } = useTranslation();

  return (
    <NavLinkAnchor to={to} lang={i18n.language} data-testid="nav-link" {...delegated}>
      {children}
      <Revealed aria-hidden>{children}</Revealed>
    </NavLinkAnchor>
  );
}

const NavLinkAnchor = styled(NavLink)`
  font-size: 18px;
  font-size: ${({ lang }) => lang === "ja" && "15px"};
  color: ${COLORS.DARK.Main};
  position: relative;
  display: block;
  font-weight: 500;
  cursor: pointer;

  &.active {
    &::before {
      content: "";
      position: absolute;
      background-color: ${COLORS.ACCENT};
      height: 7px;
      width: 135%;
      bottom: 40px;
      left: -20%;
      right: 0;
      border-radius: 0 0 30px 30px;
    }
  }

  &:hover {
    text-decoration: none;
  }
`;

const Revealed = styled.span`
  color: #ffc400;
  clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  position: absolute;
  top: 0;
  left: 0;
  filter: drop-shadow(0 0 4px white);
  transition: clip-path 1000ms;

  ${NavLinkAnchor}:hover & {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transition: clip-path 350ms;
  }
`;
